import React, { useEffect, useState } from "react";
import Carousel1 from "../../assets/images/Carousel1.jpg";
import Carousel2 from "../../assets/images/Carousel2.jpg";
import Carousel3 from "../../assets/images/Carousel3.jpg";
import Carousel4 from "../../assets/images/Carousel4.jpg";
import About from "../Pages/About/index";

const styles = {
  carousel: {
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginBottom: "150px",
  },
};

function StartPage() {
  const [index, setIndex] = useState(0);
  const carouselItems = [Carousel1, Carousel2, Carousel3, Carousel4];

  useEffect(() => {
    setTimeout(() => {
      setIndex((index + 1) % carouselItems.length);
    }, 6000);
  }, [carouselItems.length, index]);

  return (
    <>
      {carouselItems.map((item, i) => {
        const isActive = i === index;
        const isLast =
          (index === 0 && i === carouselItems.length - 1) || i === index - 1;

        if (isActive)
          return (
            <div
              key={"carousel-" + index}
              className="animated fadeIn"
              style={{
                ...styles.carousel,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                zIndex: 2,
                backgroundImage: "url(" + item + ")",
              }}
            ></div>
          );
        else if (isLast)
          return (
            <div
              key={
                "carousel-" + (index === 0)
                  ? carouselItems.length - 1
                  : index - 1
              }
              style={{
                ...styles.carousel,
                zIndex: 1,
                backgroundImage: "url(" + item + ")",
              }}
            ></div>
          );
        else return "";
      })}
      <About />
    </>
  );
}

export default StartPage;
