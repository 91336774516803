import { useEffect, useRef, useState } from "react";

export interface IProps {
  children: React.ReactNode;
  variant: "fadeIn" | "fadeInUp" | "slideInLeft" | "slideInRight";
}

function useIsVisible(ref: React.RefObject<HTMLDivElement>) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      //remove if for more than one animation
      if (entry.isIntersecting) setVisible(entry.isIntersecting);
    });
    if (ref.current !== null && observer.takeRecords().length === 0)
      observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  });

  return visible;
}

function ScrollAnimation(props: IProps) {
  //handle state
  const ref = useRef<HTMLDivElement>(null);
  const visible = useIsVisible(ref);

  return (
    <div ref={ref}>
      <div className={visible ? "animated " + props.variant : "notVisible"}>
        {props.children}
      </div>
    </div>
  );
}

export default ScrollAnimation;
