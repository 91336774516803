"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDELETE = exports.createPATCH = exports.createPUT = exports.createGET = exports.createPOST = exports.ServerResponse = exports.isErrorBody = exports.isSuccessBody = void 0;
function isSuccessBody(body) {
    return (typeof body !== null &&
        typeof body === "object" &&
        typeof body.status === "string" &&
        typeof body.data !== "undefined");
}
exports.isSuccessBody = isSuccessBody;
function isErrorBody(body) {
    return (typeof body !== null &&
        typeof body === "object" &&
        typeof body.error === "string" &&
        typeof body.message === "string" &&
        typeof body.errors !== null &&
        typeof body.errors === "object");
}
exports.isErrorBody = isErrorBody;
class ServerResponse {
    constructor(status, headers, body) {
        this.status = status;
        this.headers = headers;
        this.body = body;
    }
    isSuccess() {
        return (this.status >= 200 && this.status < 300 && isSuccessBody(this.body));
    }
    getStatus() {
        return this.status;
    }
    getHeaders() {
        return this.headers;
    }
    getBody() {
        return this.body;
    }
    getErrors(fieldMap) {
        if (isSuccessBody(this.body))
            return {};
        if (isErrorBody(this.body)) {
            const errors = {};
            for (const key of Object.keys(this.body.errors)) {
                const error = this.body.errors[key];
                if (!fieldMap[key])
                    throw Error("Undefined error " + key + ": " + error);
                const mappedKey = fieldMap[key];
                errors[mappedKey] = error;
            }
            return errors;
        }
        throw Error("connector::getErrors: Is not a error response.");
    }
    getResult() {
        if (isSuccessBody(this.body))
            return this.body;
        throw Error("connector::getResult: Is not a success response.");
    }
}
exports.ServerResponse = ServerResponse;
function request(url, method, apiKey, conditions, projection, limit, body, index, direction, next) {
    return __awaiter(this, void 0, void 0, function* () {
        const encodedConditions = btoa(JSON.stringify(conditions));
        const encodedProjection = btoa(JSON.stringify(projection));
        let params = `?condition=${encodedConditions}&projection=${encodedProjection}&limit=${limit}`;
        if (index)
            params += `&sort=${index}`;
        if (direction)
            params += `&direction=${direction}`;
        if (next)
            params += `&next=${next}`;
        const options = {
            method,
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
                "Content-Type": "application/json; charset=utf8",
                "x-api-key": apiKey,
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: method !== "GET" && method !== "DELETE" ? JSON.stringify(body) : null,
        };
        const path = method !== "POST" ? url + params : url;
        const response = yield fetch(path, options);
        return parseJsonResponse(response);
    });
}
function parseHeaders(response) {
    const headers = {};
    for (const [key, value] of response.headers.entries()) {
        headers[key] = value;
    }
    return headers;
}
function parseJsonResponse(response) {
    return __awaiter(this, void 0, void 0, function* () {
        const { status } = response;
        return new ServerResponse(status, parseHeaders(response), yield response.json());
    });
}
function createPOST(url, apiKey) {
    return function (body) {
        return request(url, "POST", apiKey, {}, [], 1, body);
    };
}
exports.createPOST = createPOST;
function createGET(url, apiKey) {
    return function (condition, projection, limit, index, direction, next) {
        return request(url, "GET", apiKey, condition, projection, limit, {}, index, direction, next);
    };
}
exports.createGET = createGET;
function createPUT(url, apiKey) {
    return function (condition, projection, body) {
        return request(url, "PUT", apiKey, condition, projection, 1, body);
    };
}
exports.createPUT = createPUT;
function createPATCH(url, apiKey) {
    return function (condition, body) {
        return request(url, "PATCH", apiKey, condition, [], 1, body);
    };
}
exports.createPATCH = createPATCH;
function createDELETE(url, apiKey) {
    return function (condition) {
        return request(url, "DELETE", apiKey, condition, [], 1, {});
    };
}
exports.createDELETE = createDELETE;
