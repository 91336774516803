import React, { Component } from "react";
import SectionHead from "../Elements/SectionHeadline";
import literature from "../../../configs/Literature";
import catalogue from "../../../configs/Catalogues";
import ScrollAnimation from "../Shared/ScrollAnimation";
import { Col, Container, Row } from "@maxeb/admin-ui";

class LiteraturePage extends Component {
  render() {
    const literatures = literature;
    const catalogues = catalogue;
    return (
      <Container>
        <Row spacing={32} vertical root={0}>
          <Col>
            <SectionHead text="LITERATURE" showBackToTop={false} noLine />
          </Col>
          <Col>
            <ScrollAnimation variant="fadeIn">
              <Row spacing={32} vertical root={0}>
                {literatures.data.map((literature, index) => {
                  return (
                    <Col
                      xs={5}
                      sm={10 / 3}
                      md={10 / 4}
                      key={"Literature-" + index}
                    >
                      <a
                        href={literature.pdf}
                        className="link-std"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt={literature.title}
                          style={{ maxWidth: "100%" }}
                          src={literature.image}
                        />
                        <p>
                          <span style={{ fontWeight: 600 }}>
                            {literature.title}
                          </span>
                          <br />
                          {literature.artist}
                        </p>
                      </a>
                    </Col>
                  );
                })}
              </Row>
            </ScrollAnimation>
          </Col>
          <Col>
            <SectionHead text="CATALOGUES" showBackToTop={false} noLine />
          </Col>
          <Col>
            <ScrollAnimation variant="fadeIn">
              <Row spacing={32} vertical root={0}>
                {catalogues.data.map((catalogue, index) => {
                  const wrapper = (
                    toLink: string | undefined,
                    toWrap: React.ReactNode
                  ) => {
                    if (!toLink) return toWrap;
                    return (
                      <a
                        href={toLink}
                        className="link-std"
                        target="_blank"
                        rel="noopener noreferrer"
                        key={"Catalogues-" + index}
                      >
                        {toWrap}
                      </a>
                    );
                  };
                  return (
                    <Col
                      xs={5}
                      sm={10 / 3}
                      md={10 / 4}
                      key={
                        (!catalogue.pdf && "Catalogues-" + index) ||
                        (catalogue.pdf && "Catalogues-" + index)
                      }
                    >
                      {wrapper(
                        catalogue.pdf,
                        <>
                          <img
                            src={catalogue.image}
                            width="100%"
                            alt={catalogue.title}
                          />
                          <p>
                            <span style={{ fontWeight: 600 }}>
                              {catalogue.title}
                            </span>
                            <br />
                            {catalogue.museum}
                            <br />
                            {catalogue.year}
                          </p>
                        </>
                      )}
                    </Col>
                  );
                })}
              </Row>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default LiteraturePage;
