import IExhibitions from "../interface/IExhibitions";

const exhibition: IExhibitions = {
  data: [
    {
      year: "2022/23",
      text: "Municipal Art Gallery, Rishon Lezion, Gordon - London House – The Temple of Life.",
    },
    {
      year: "2022",
      text: "Albertina, Vienna, Austria – exhibition of multiple works from Albertina permanent collection.",
    },
    {
      year: "2019",
      text: "‘Where Art Thou?’, State Russia Museum, St Petersburg, Russia",
    },
    {
      year: "2018",
      text: "‘Summer Exhibition’, Royal Academy of Arts, London, UK",
    },
    { year: "2017", text: "Albertina, Vienna, Austria" },
    {
      year: "2016",
      text: "‘Harmony of Dissonance', U + I Gallery, London, UK",
    },
    {
      year: "2016",
      text: "‘Intricate Affinities’, Petach Tikvah Museum of Art, Israel",
    },
    {
      year: "2015",
      text: "Leningrad Underground New Museum, St Petersburg, Russia",
    },
    {
      year: "2013",
      text: "‘Observationes’, Museum of Israeli Art, Ramat Gan, Tel Aviv, Israel",
    },
    { year: "2010", text: "‘Sasha Okun’, Paddington Central, London, UK" },
    {
      year: "2009",
      text: "‘Wild Exaggeration’, Haifa Museum of Art, Haifa, Israel. ‘Nudes’, Artists’ House, Jerusalem, Israel.",
    },
    {
      year: "2006",
      text: "‘Times of Change’, Russian Museum, St. Petersburg, Russia",
    },
    {
      year: "2005",
      text: "Mishkenot Sheananim Gallery, Jerusalem, Israel Contemporary Art Festival, Manege Central Exhibition Hall, St. Petersburg, Russia.",
    },
    {
      year: "2002–2003",
      text: "‘A Matter of Taste’, Israel Museum, Jerusalem, Israel.",
    },
    {
      year: "2002",
      text: "Manege Central Exhibition Hall, St. Petersburg, Russia.",
    },
    {
      year: "2001",
      text: "New Manege Exhibition Hall, Moscow, Russia. Manege Central Exhibition Center, St. Petersburg, Russia.",
    },
    {
      year: "1999",
      text: "Salon des Beaux Arts, Grand Palais, Paris, France.",
    },
    {
      year: "1998",
      text: "Garden as a Metaphor sculpture exhibition, Botanical Garden, Jerusalem, Israel. Studio S – Arte Contemporanea, Rome, Italy. Comic Books in Russia, Borey Art Gallery, St. Petersburg, Russia.",
    },
    { year: "1997", text: "Klutznick Museum, Washington D. C., USA" },
    {
      year: "1996",
      text: "The Dimension of Sound, Artists’ House, Jerusalem, Israel.",
    },
    {
      year: "1995",
      text: "An Artist Designs His Own Memorial, Artists’ House, Jerusalem, Israel.",
    },
    {
      year: "1994",
      text: "Megawatt, Art Focus, Power Plant, Jerusalem, Israel.",
    },
    { year: "1993", text: "Russian Museum, St. Petersburg, Russia." },
    {
      year: "1992",
      text: "Negev Museum of Art, Be’er Sheva, Israel. Marble Palace, St. Petersburg, Russia.",
    },
    {
      year: "1991",
      text: "Israel Art Month, Jerusalem Theater, Jerusalem, Israel. Denver University, Denver, Colorado, USA. White Raven Gallery, Jerusalem, Israel.",
    },
    {
      year: "1990",
      text: "Ohio State University, Columbus, Ohio, USA.",
    },
    {
      year: "1989",
      text: "Artists for Human Rights, Bugrashov Gallery, Tel Aviv, Israel. Home, Mishkenot Sha’ananim, Jerusalem, Israel. Classics in Context Festival, Louisville, Kentucky, USA.",
    },
    {
      year: "1988",
      text: "Jerusalem Theater, Jerusalem, Israel. Nora Gallery, Jerusalem, Israel. Ariadna Gallery, St. Petersburg, Russia.",
    },
    { year: "1987, ’88, ’89 ", text: "Horace Richter Gallery, Jaffa, Israel." },
    { year: "1987", text: "Cité internationale des arts, Paris, France." },
    {
      year: "1986",
      text: "Six Artists, National Museum, Bogota, Columbia. Self-Portrait, Artists’ House, Jerusalem, Israel.",
    },
    {
      year: "1985",
      text: "Beit Ariela, Tel Aviv, Israel. Salon des Beaux Arts, Grand Palais, Paris, France.",
    },
    {
      year: "1984",
      text: "Florence Miller Center, 10 Years of the Jerusalem Print Workshop, Jerusalem, Israel.",
    },
    { year: "1983", text: "Art Pavilion, Yehoshua Gardens, Tel Aviv, Israel." },
    {
      year: "1981, ’82, ’83, ’85, ’86 and ’88",
      text: "Debel Gallery, Jerusalem, Israel.",
    },
    {
      year: "1977",
      text: "Skirball Museum, Los Angeles, California, USA. Klutznick Museum, Washington D. C., USA. Kiplinger Gallery, Washington D. C., USA.",
    },
    {
      year: "1976",
      text: "Yehuda L. Magnes Museum, Berkeley, California, USA.",
    },
    { year: "1975", text: "Nevsky House of Culture, St. Petersburg, Russia." },
    {
      year: "1974",
      text: "Ivan Gaza House of Culture, St. Petersburg, Russia.",
    },
  ],
  pending: false,
  error: null,
  receivedAt: null,
};

export default exhibition;
