import React, { Component } from "react";
import ScrollAnimation from "../Shared/ScrollAnimation";

export interface IProps {
  text: string | boolean;
  showBackToTop: boolean;
  noLine: boolean;
}

const style: { [key: string]: React.CSSProperties } = {
  sectionHead: {
    margin: "88px 0px 44px 0px",
  },
  sectionHeadFirst: {
    margin: "0px 0px 44px 0px",
  },
  sectionheadHeadline: {
    marginTop: "16px",
    fontSize: "1rem",
    fontWeight: 700,
  },
  sectionHeadSeperatorWrapper: {
    width: "310px",
    overflow: "hidden",
  },
  sectionHeadSeperator: {
    display: "block",
    height: "1px",
    width: "310px",
    backgroundColor: "#000",
  },
  sectionHeadToTop: {
    float: "right",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
  },
};

class SectionHeadline extends Component<IProps> {
  render() {
    const { text, showBackToTop = true, noLine = false } = this.props;
    return (
      <div>
        {!noLine && (
          <div style={style.sectionHeadSeperatorWrapper}>
            <ScrollAnimation variant="slideInLeft">
              <span style={style.sectionHeadSeperator}></span>
            </ScrollAnimation>
          </div>
        )}
        {text !== false && (
          <ScrollAnimation variant="fadeInUp">
            <h5 style={style.sectionheadHeadline}>
              {text}{" "}
              {showBackToTop === true && (
                <small style={style.sectionHeadToTop}>
                  <a className="link-std" style={style.link} href="/about">
                    BACK TO TOP
                  </a>
                </small>
              )}
            </h5>
          </ScrollAnimation>
        )}
      </div>
    );
  }
}

export default SectionHeadline;
