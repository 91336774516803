import React from "react";
import { ITheme, useClasses } from "@maxeb/admin-ui";
import { Link } from "react-router-dom";
import ImageLoader from "../../Shared/ImageLoader";
import { CollectionPublic } from "@maxeb/art-sdk/dist/interfaces/Collection";

const style = (theme: ITheme) => ({
  link: {
    textAlign: "center",
    color: "#212529",
    "&:hover": {
      textDecoration: "none",
      fontWeight: 700,
    },
  },
  img: {
    maxWidth: "100%",
    width: "100% !important",
    height: "auto !important",
    aspectRatio: 1,
    objectFit: "cover",
  },
  text: {
    textDecoration: "underline",
    display: "block",
    marginTop: "8px",
  },
});

interface IProps {
  id: string;
  name: string;
  thumb: CollectionPublic["thumb"];
}

function Serie(props: IProps) {
  const classes = useClasses(style);

  return (
    <Link className={classes.link} to={`/artworks/${props.id}`}>
      {props.thumb && (
        <ImageLoader
          className={classes.img}
          hash={props.thumb.hash}
          src={props.thumb["xs-url"]}
          alt={props.name}
        />
      )}

      <div className={classes.text}>{props.name}</div>
    </Link>
  );
}

export default Serie;
