import IAbout from "../interface/IAbout";

const about: IAbout = {
  cv: {
    data: [
      { year: "1949", text: "Born in Leningrad, USSR." },
      {
        year: "1972",
        text: "Graduated from the Vera Mukhina Higher School of Art and Design.",
      },
      { year: "1979", text: "Immigrated to Israel." },
      {
        year: "1986",
        text: "Senior lecturer, Bezalel Academy of Arts and Design, Jerusalem, Israel.",
      },
      {
        year: "1988",
        text: "Adjunct Professor, Bellarmine College, Kentucky, USA.",
      },
      {
        year: "1988–2002",
        text: "Lecturer, Emunah College, Jerusalem, Israel",
      },
      { year: "2020", text: "Jewish Museum, New York" },
      { year: "2020", text: "The Levin Collection" },
    ],
    pending: false,
    error: null,
    receivedAt: null,
  },
  collections: {
    data: [
      "State Hermitage Museum, St. Petersburg, Russia",
      "Albertina, Vienna, Austria",
      "Israel Museum, Jerusalem, Israel",
      "Israel Museum, Tel Aviv, Israel",
      "Stieglitz State Academy of Art and Design, St. Petersburg, Russia",
      "State Russian Museum, St. Petersburg, Russia",
      "Yad Vashem, Jerusalem, Israel",
      "Negev Museum of Art, Be’er Sheva, Israel; Janco-Dada Museum, Ein Hod, Israel",
      "Mishkan Le’Omanut Museum of Art, Ein Harod, Israel",
      "Bar-David Museum, Kibbutz Baram, Israel",
      "Museum of Art, Vitebsk, Belarus",
      "Private collections in Israel, Russia, Poland, France, Italy, USA, Australia, Germany, Austria, Canada, and Mexico.",
    ],
    pending: false,
    error: null,
    receivedAt: null,
  },
  fellowshipsAndAwards: {
    data: [
      "Ish Shalom Life Achievement Prize, 2014",
      "Jefferson County Honorary Citizen, Kentucky, U.S.A. 1989",
      "Cite International des Arts, Residence Fellowship, Israel Ministry of Foreign Affairs (Cultural Exchange), 1987",
      "Kentucky Colonel honorary title, Kentucky, U.S.A. 1989",
      "Gestetner Fellowship, 1985 Ofer Feniger Award for a Young Artist, 1983",
    ],
    pending: false,
    error: null,
    receivedAt: null,
  },
};

export default about;
