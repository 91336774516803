import React from "react";
import okun from "../../../assets/images/okun_about.jpg";
import ScrollAnimation from "../../Shared/ScrollAnimation";
import { Col, ITheme, Row, Typo, useClasses } from "@maxeb/admin-ui";

const styles = (theme: ITheme) => ({
  teaser: {
    fontWeight: "600",
  },
  quote: {
    fontFamily: "Times New Roman",
    color: "#676767",
    fontStyle: "italic",
  },
  image: {
    maxWidth: "100%",
  },
  link: {
    fontWeight: 500,
  },
});

export interface IProps {}

export default function AboutArtist(props: IProps) {
  const classes = useClasses(styles, props);

  return (
    <Row vertical spacing={32} root={0}>
      <Col lg={5}>
        <ScrollAnimation variant="fadeIn">
          <Typo variant="p" margin="0" override={{ className: classes.teaser }}>
            Sasha Okun was born in 1949 in Leningrad (St. Petersburg), Russia.
            He now lives and works in Jerusalem, Israel. He trained at Solomon
            Levin, Pioneer Palace Studio, St. Petersberg (1960–65) and then
            Mukhina Higher Industrial Institute of Art, Leningrad, now St
            Petersburg Stieglitz State Academy of Art and Design, Moscow
            (1966–72).
          </Typo>
          <Typo variant="p">
            In the mid-1970s Okun was part of a group of young artists
            exhibiting in nonconformist art exhibitions in public parks and
            alternative spaces in Leningrad. During that period, Okun and other
            friends were arrested, detained and threatened on a number of
            occasions for not conforming with the government directives at that
            time. Eventually, feeling at odds with the socio-political climate,
            in 1979 Okun and his family left the Soviet Union and emigrated to
            Rome where he spent some months touring and studying Classical and
            Renaissance art. Then, instead of emigrating to the United States
            like many Russian dissidents, the family moved to Israel where they
            have lived ever since. Alongside pursuing his own art practice,
            since 1986 Okun has also taught at the Bezalel Academy of Art in
            Jerusalem. He has exhibited throughout Israel, Europe and America
            and is included in numerous important museum collections{" "}
            <a
              href="/assets/documents/pdfs/CV.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              CV
            </a>
            .
          </Typo>
          <Typo variant="p" override={{ className: classes.quote }}>
            I cannot depart from what, in one way or another, relates to the
            topic of human existence, everyday life and death. Perhaps that is
            why an older, lived-in body is more interesting to me than the
            90/60/90 of the Hollywood starlets. Most of us are not really wise
            men but also not complete idiots, for us life is a tragicomedy. And
            for me it is the most interesting and productive genre. Eternal and
            short-lived, faith and cynicism, sanctity and sacrilege, soaring
            spirit and awful vulgarity–what a marvelous cocktail! Lifting the
            mundane to the heavens and plunging the heavens into a muddy puddle.
            This, basically, is what I do. I work in traditional (in terms of
            technique), classical, if you will, manner of painting, intended for
            high plots–mythological, historical themes. My themes are, in
            essence, low: a man drowns; watching telly; picking under nails.
            However, through to the clashing of the incompatible (a drowned man)
            an archetype merges (Pieta). By the way, with regards to plots: at
            the end of the 19th century, Georges Polti determined that there are
            only 36 plots in literature (Borges was much more frugal–he limited
            them to only four). Since then, the 37th plot has not been invented.
            Therefore, it is not the plot that’s important, but the intonation
            with which it is being told. In painting the intonation is a
            flexible solution. I think I speak with my own voice. The mixture of
            a high style with a low plot seems to me to be an adequate
            expression of total absurdity that reigns around us.
            <br />
            <br />
            SASHA OKUN
          </Typo>
        </ScrollAnimation>
      </Col>
      <Col lg={5}>
        <ScrollAnimation variant="fadeInUp">
          <img src={okun} alt="Artist" className={classes.image} />
        </ScrollAnimation>
      </Col>
    </Row>
  );
}
