import React from "react";
import SectionHead from "../Elements/SectionHeadline";
import okunImg from "../../assets/images/okun_contact.jpg";
import ScrollAnimation from "../Shared/ScrollAnimation";
import { Col, Container, ITheme, Row, useClasses } from "@maxeb/admin-ui";

const style = (theme: ITheme) => ({
  list: {
    listStyle: "none",
    padding: 0,
  },
});

export default function Contact() {
  const classes = useClasses(style);
  return (
    <Container>
      <Row verticalAlign="bottom">
        <Col md="rest">
          <SectionHead noLine text="REPRESENTED BY" showBackToTop={false} />
          <ul className={classes.list}>
            <li style={{ paddingBottom: "16px" }}>Mr Michael Marx</li>
            <li style={{ paddingBottom: "16px" }}>Artso Limited</li>
            <li>Acre House</li>
            <li>11/15 William Road</li>
            <li>London NW1 3ER</li>
            <li style={{ paddingBottom: "16px" }}>United Kingdom</li>
            <li>
              <a href="mailTo:mhm@artsoltd.co.uk">mhm@artsoltd.co.uk</a>
            </li>
            <li>+44 7770 610044</li>
          </ul>
        </Col>
        <Col md="auto">
          <ScrollAnimation variant="fadeIn">
            <img style={{ maxWidth: "100%" }} src={okunImg} alt="Okun" />
          </ScrollAnimation>
        </Col>
      </Row>
    </Container>
  );
}
