import ICatalogues from "../interface/ICatalogues";
import thumb1 from "../resources/assets/images/catalogues/where_are_thou.png";
import thumb2 from "../resources/assets/images/catalogues/Observations_Ramat_Gan.jpeg";
import thumb3 from "../resources/assets/images/catalogues/PaddingtonCentral.png";
import thumb4 from "../resources/assets/images/catalogues/Russian Catalogue.jpg";
import thumb5 from "../resources/assets/images/catalogues/templeOfLife.png";
import thumb6 from "../resources/assets/images/catalogues/albertina.png";

const catalogues: ICatalogues = {
  data: [
    {
      image: thumb6,
      title: "SASHA OKUN IN VIENNA",
      museum: "Albertina, Vienna",
      year: "2023",
      pdf: "/assets/documents/pdfs/Sasha-Okun_in-Vienna.pdf",
    },
    {
      image: thumb5,
      title: "TEMPLE OF LIFE",
      museum: "The Temple of Life",
      year: "2022",
      pdf: "/assets/documents/pdfs/templeOfLife.pdf",
    },
    {
      image: thumb4,
      title: "Avant-Garde on the Neva",
      museum: "-",
      year: "2020",
      pdf: "/assets/documents/pdfs/Sasha Neva Book Oct 20.pdf",
    },
    {
      image: thumb1,
      title: "WHERE ART THOU",
      museum: "State Russia Museusm",
      year: "2019",
      pdf: "/assets/documents/pdfs/Where_are_thou_2019.pdf",
    },
    {
      image: thumb2,
      title: "OBSERVATIONES",
      museum: "Ramat Gan",
      year: "2013",
    },
    {
      image: thumb3,
      title: "PADDINGTON CENTRAL",
      museum: "London",
      year: "2010",
    },
  ],
  pending: false,
  error: null,
  receivedAt: null,
};

export default catalogues;
