import React, { Component } from "react";
import AboutArtist from "./Bio";
import AboutArtistFilm from "./Film";
import AboutArtistCV from "./CV";
import SubMenu from "./SubMenu";
import withRouter from "../../Elements/WithRouterWrapper";
import SectionHeadLine from "../../Elements/SectionHeadline";

import ScrollAnimation from "../../Shared/ScrollAnimation";
import { Container } from "@maxeb/admin-ui";

const style = {
  sectionHead: {
    margin: "88px 0px 44px 0px",
  },
  sectionHeadFirst: {
    margin: "0px 0px 44px 0px",
  },
};

class AboutPage extends Component {
  render() {
    return (
      <Container>
        <div style={style.sectionHeadFirst} id="bio">
          <SectionHeadLine text={false} showBackToTop={true} noLine={false} />
        </div>
        <ScrollAnimation variant="fadeIn">
          <SubMenu {...this.props} />
        </ScrollAnimation>
        <AboutArtist />
        {/*         <div style={style.sectionHead} id="film">
          <SectionHeadLine
            text="SHORT VIDEOS"
            showBackToTop={true}
            noLine={false}
          />
        </div> 
        <AboutArtistFilm />
        */}
        <div style={style.sectionHead} id="cv">
          <SectionHeadLine text="CV" showBackToTop={true} noLine={false} />
        </div>
        <AboutArtistCV {...this.props} />
      </Container>
    );
  }
}
export default withRouter(AboutPage);
