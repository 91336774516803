import React, { useEffect, useState } from "react";
import { ITheme, useClasses } from "@maxeb/admin-ui";
import { Collection } from "@maxeb/art-sdk";
import { useParams } from "react-router-dom";

interface IProps {}
interface IState {
  id: string;
  collection: string;
  inited: boolean;
}

const styles = (theme: ITheme) => ({
  text: {
    fontWeight: 700,
  },
});

async function getCollection(state: IState, setState: (state: IState) => void) {
  setState({ ...state, inited: true });
  try {
    const response = await Collection.getPublic(
      { id: state.id, team: "bccd195b-c067-473d-93f9-dbc3d442e539" },
      ["id", "name"],
      10,
      "senaryIndex",
      "ASC"
    );

    if (response.isSuccess()) {
      const result = response.getResult();
      return setState({
        ...state,
        collection: result.data[0]?.name || "",
        inited: true,
      });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function CollectionName(props: IProps) {
  const params = useParams<{ id: string }>();
  const classes = useClasses(styles);

  const [state, setState] = useState<IState>({
    id: params.id || "",
    collection: "",
    inited: false,
  });

  useEffect(() => {
    if (!state.inited) getCollection(state, setState);
  }, [state, props]);

  return <div className={classes.text}>{state.collection || ""}</div>;
}
