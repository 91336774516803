import React, { Component } from "react";
import { Col, Container, Row } from "@maxeb/admin-ui";
import ScrollAnimation from "../Shared/ScrollAnimation";
import SectionHead from "../Elements/SectionHeadline";

export default class AboutArtistFilm extends Component {
  render() {
    return (
      <Container>
        <Row vertical spacing={32} root={0}>
          <Col>
            <SectionHead text="SHORT VIDEOS" showBackToTop={false} noLine />
          </Col>
          <Col md={5}>
            <ScrollAnimation variant="fadeInUp">
              <div className="bio-film">
                <iframe
                  title="Sasha Okun | Film 4"
                  width="100%"
                  src="https://www.youtube.com/embed/mH4ZB1V2nR0?si=OOgK1SIMnYUnW14W"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
              <div style={{ marginTop: "8px" }}>'The Temple of life', 2023</div>
            </ScrollAnimation>
          </Col>
          <Col md={5}>
            <ScrollAnimation variant="fadeInUp">
              <div className="bio-film">
                <iframe
                  title="Sasha Okun | Film 3"
                  width="100%"
                  src="https://www.youtube.com/embed/Gqo7HVuVNqc"
                  //src="https://www.youtube.com/embed/Wh2kpBDngyY"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
              <div style={{ marginTop: "8px" }}>
                'Villa dei Misteri' in the Studio, 2020
              </div>
            </ScrollAnimation>
          </Col>
          <Col md={5}>
            <ScrollAnimation variant="fadeInUp">
              <div className="bio-film">
                <iframe
                  width="100%"
                  src="https://www.youtube.com/embed/CvUQJECSrAI" //LnfLFGQZ3J0
                  title="Sasha Okun | Film 2"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
              <div style={{ marginTop: "8px" }}>
                Sasha Okun, State Russia Museum, 2019
              </div>
            </ScrollAnimation>
          </Col>
          <Col md={5}>
            <ScrollAnimation variant="fadeInUp">
              <div className="bio-film">
                <iframe
                  width="100%"
                  src="https://www.youtube.com/embed/LnfLFGQZ3J0" //LnfLFGQZ3J0
                  title="Sasha Okun | Film 1"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </div>
              <div style={{ marginTop: "8px" }}>
                Sasha Okun in the Studio, 2018
              </div>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    );
  }
}
