import React, { Component } from "react";

export interface IProps {
  vAlign: string | number;
  hAlign:
    | "center"
    | "end"
    | "justify"
    | "left"
    | "match-parent"
    | "right"
    | "start";
  children: React.ReactNode;
  height?: string;
}

class Align extends Component<IProps> {
  render() {
    return (
      <div style={{ display: "table", height: "100%", width: "100%" }}>
        <div
          style={{
            display: "table-cell",
            verticalAlign: this.props.vAlign,
            textAlign: this.props.hAlign,
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Align;
