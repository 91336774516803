import ILiterature from "../interface/ILiterature";
import image from "../resources/assets/images/literatures/Where_Are_Thou.jpg";
import image2 from "../resources/assets/images/literatures/A_Harmony.jpg";
import image3 from "../resources/assets/images/literatures/In_Love.jpg";
import image4 from "../resources/assets/images/literatures/Theatre of the abs.jpg";
import image5 from "../resources/assets/images/literatures/The_Human.jpg";
import image6 from "../resources/assets/images/literatures/Back to.jpg";
//import image7 from "../resources/assets/images/literatures/lecture.png";

const literature: ILiterature = {
    data: [
      {
        title: "'Where Art Thou?'",
        artist: "Hagai Segev",
        image: image,
        pdf: "/assets/documents/pdfs/WhereArtThough.pdf",
      },
      {
        title: "'A Harmony of Dissonances'",
        artist: "Hagai Segev",
        image: image2,
        pdf: "/assets/documents/pdfs/HarmonyOfDissonance.pdf",
      },
      {
        title: "'In Love of the Flesh'",
        artist: "Lucian Freud and Sasha Okun",
        image: image3,
        pdf: "/assets/documents/pdfs/InLoveoftheFlesh.pdf",
      },
      {
        title: "'Theatre of the Absurd'",
        artist: "Smadar Sheffi",
        image: image4, 
        pdf: "/assets/documents/pdfs/TheatreoftheAbsurd.pdf",
      },
      {
        title: "'The Human Comedy 2012'",
        artist: "Gideon Ofrat",
        image: image5,
        pdf: "/assets/documents/pdfs/GideonOfratTheHumanComedy2012.pdf",
      },
      {
        title: "'Back to Present 2012'",
        artist: "Irina Alter",
        image: image6, 
        pdf: "/assets/documents/pdfs/IrinaAlterBacktoPresent2012.pdf",
      },
      /* {
        title: "'Lecture Hebrew University 2019'",
        artist: "Ila Rodov",
        image: image7,
        pdf: "/assets/documents/pdfs/IlaRodovTheHebrewUniversityofJerusalem.pdf",
      }, */
    ],
    pending: false,
    error: null,
    receivedAt: null,
  };

  export default literature;