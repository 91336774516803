import React, { Component } from "react";

class PendingContent extends Component {
  render() {
    return (
      <div className="container">
        <div
          style={{
            display: "table",
            minHeight: "calc(100vh - 140px)",
            width: "100%",
          }}
        >
          <p
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              textAlign: "center",
            }}
          >
            Page is loading...
          </p>
        </div>
      </div>
    );
  }
}

export default PendingContent;
