import { Col, ITheme, Row, useClasses } from "@maxeb/admin-ui";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";

export interface IProps {
  open: boolean;
  onClose?: () => void;
  next: () => void;
  previous: () => void;
  title?: React.ReactNode;
  src?: string;
}

const styles = (theme: ITheme) => ({
  shadow: {
    backgroundColor: "rgb(21, 21, 21)",
    position: "fixed",
    width: "100%",
    height: "calc(100% - 39px)",
    zIndex: "1000",
    left: 0,
    top: 0,
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      padding: "16px 0px",
      height: "100%",
    },
  },
  title: {
    position: "fixed",
    backgroundColor: theme.palette.get("default"),
    margin: "0px 0px 0px  !important",
    padding: "8px 16px !important",
    fontSize: "1.2rem",
    textAlign: "left",
    bottom: 0,
    left: 0,
    right: 0,
  },
  img: {
    maxHeight: "calc(100vh - 39px)",
    maxWidth: "100%",
    boxShadow: "rgb(0 0 0 / 40%) 0px 0px 4px 0px",
    [theme.breakpoints.up("lg")]: {
      height: "calc(100vh - 71px)",
    },
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginTop: "4px",
  },
  close: {
    position: "fixed",
    right: "16px",
    top: "16px",
    color: "#fff",
    fontSize: "1.3rem",
    cursor: "pointer",
  },
  chevronLeft: {
    fontSize: "2rem",
    cursor: "pointer",
    color: "#fff",
    paddingRight: "25%",
    paddingTop: "5px",
  },
  chevronRight: {
    fontSize: "2rem",
    cursor: "pointer",
    color: "#fff",
    paddingLeft: "25%",
    paddingTop: "5px",
  },
});

export default function LightBox(props: IProps) {
  const classes = useClasses(styles, props);

  if (props.open)
    return (
      <div
        className={classes.shadow}
        onClick={() => {
          if (props.onClose) props.onClose();
        }}
      >
        <div className={classes.close}>CLOSE</div>
        <Row stretch verticalAlign="center">
          <Col>
            <img
              alt=""
              className={classes.img}
              src={props.src}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
            <Col>
              <FontAwesomeIcon
                className={classes.chevronLeft}
                icon={faChevronLeft}
                onClick={(event) => {
                  event.stopPropagation();
                  props.previous();
                }}
              />
              <FontAwesomeIcon
                className={classes.chevronRight}
                icon={faChevronRight}
                onClick={(event) => {
                  event.stopPropagation();
                  props.next();
                }}
              />
            </Col>
          </Col>
        </Row>
      </div>
    );
  else return <></>;
}
