import React from "react";
import { Col, ITheme, Row, Typo, useClasses } from "@maxeb/admin-ui";
import about from "../../../../configs/About";
import ScrollAnimation from "../../Shared/ScrollAnimation";

const styles = (theme: ITheme) => ({
  h5: {
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: 1.2,
    marginBottom: "0.5rem",
  },
  collection: {
    marginTop: "1rem",
  },
  link: {
    color: "rgb(33,37,41)",
  },
});

export interface IProps {}

export default function AboutArtistCV(props: IProps) {
  const { cv, collections, fellowshipsAndAwards } = about;
  const classes = useClasses(styles, props);

  return (
    <Row spacing={32} vertical root={0}>
      <Col md={5}>
        <ScrollAnimation variant="fadeIn">
          {cv.data.map((value, index) => {
            return (
              <p key={"cv-" + index}>
                {value.year}
                <br></br>
                {value.text}
              </p>
            );
          })}
        </ScrollAnimation>
      </Col>
      <Col md={5}>
        <ScrollAnimation variant="fadeIn">
          <h5 className={`${classes.h5} ${classes.collection}`}>COLLECTIONS</h5>
          <Typo variant="p">
            {collections.data.map((value, index) => {
              return (
                <span key={"collection-" + index}>
                  {value}
                  <br></br>
                </span>
              );
            })}
            <br />
          </Typo>
          <h5 className={classes.h5}>FELLOWSHIPS AND AWARDS</h5>
          <Typo variant="p">
            {fellowshipsAndAwards.data.map((value, index) => {
              return (
                <span key={"fAA-" + index}>
                  {value}
                  <br></br>
                </span>
              );
            })}
          </Typo>
          <br />
          <a
            href="/assets/documents/pdfs/CV.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            CLICK TO DOWNLOAD FULL CV
          </a>
        </ScrollAnimation>
      </Col>
    </Row>
  );
}
