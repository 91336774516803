import React, { Component } from "react";
import SectionHead from "../Elements/SectionHeadline";
import exhibition from "../../../configs/Exhibitions";
import { Col, Container, Row } from "@maxeb/admin-ui";
import ScrollAnimation from "../Shared/ScrollAnimation";

class Exhibition extends Component {
  render() {
    const exhibitions = exhibition;
    return (
      <Container>
        <Row>
          <Col>
            <SectionHead text="EXHIBITIONS" showBackToTop={false} noLine />
          </Col>
        </Row>
        <Row spacing={32} vertical root={0}>
          <Col sm={5}>
            <ScrollAnimation variant="slideInLeft">
              {exhibitions.data
                .slice(0, exhibitions.data.length / 2)
                .map((n, i) => {
                  return (
                    <div
                      style={{ marginBottom: "32px", paddingRight: "12px" }}
                      key={"ex1" + i}
                    >
                      <div>
                        <b>{n.year}</b>
                      </div>
                      <div>{n.text}</div>
                    </div>
                  );
                })}
            </ScrollAnimation>
          </Col>
          <Col sm={5}>
            <ScrollAnimation variant="slideInRight">
              {exhibitions.data
                .slice(exhibitions.data.length / 2, exhibitions.data.length)
                .map((n, i) => {
                  return (
                    <div
                      style={{ marginBottom: "32px", paddingLeft: "12px" }}
                      key={"ex2" + i}
                    >
                      <div>
                        <b>{n.year}</b>
                      </div>
                      <div>{n.text}</div>
                    </div>
                  );
                })}
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Exhibition;
