import React, { Component } from "react";

export interface IProps {
  text: string;
}

class ErrorContent extends Component<IProps> {
  render() {
    const { text } = this.props;
    return (
      <div className="container">
        <div className="center-content-v center-content-h">
          <h3>
            Sorry, <small style={{ fontSize: "0.7em" }}>an error occured</small>
          </h3>
          <p>{text}</p>
        </div>
      </div>
    );
  }
}

export default ErrorContent;
