import React, { Component } from "react";
import Align from "./Align";

class Footer extends Component {
  render() {
    return (
      <footer>
        <div
          style={{
            width: "100%",
            backgroundColor: "rgb(21, 21, 21)",
            marginTop: "32px",
            color: "#fff",
          }}
        >
          <div className="container" style={{ padding: "16px", height: 100 }}>
            <Align hAlign="start" vAlign="middle">
              <div className="row">
                <div className="col-6">
                  Copyright Sasha Okun{" "}
                  <span style={{ fontSize: "1rem" }}>&copy;</span>
                </div>
                <div className="col-6" style={{ textAlign: "right" }}>
                  <a className="button-footer" href="/contact">
                    Contact
                  </a>
                </div>
              </div>
            </Align>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
