import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { options as ArtOptions } from "@maxeb/art-sdk";
import { init } from "@maxeb/admin-ui";

ArtOptions.setApiKey("wpypxW1yYd4DN2C9q16959FDHj5Gc7DXhU7JHARd");
ArtOptions.setUrl("https://art.webcollector.at");

const palette = {
  primary: "#246cb5",
  secondary: "#2c689c",
  tertiary: "#333333",
  danger: "#b10711",
  success: "#559554",
  warning: "#e8db05",
  default: "#d9d9d9",
  body: "#ececec",
  grey: "#7d7d7d",
  black: "#3e3e3e",
  lightGrey: "#ececec",
  background: "#ececec",
  formBackground: "rgba(224, 224, 224, 0.6)",
  formBackgroundActive: "rgba(224, 224, 224, 1)",
};

init({ palette: palette });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
