import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function withRouter(Component: Function) {
  interface IComponentWithRouterProp {
    [x: string]: any;
  }

  function ComponentWithRouterProp(props: IComponentWithRouterProp) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
export default withRouter;
