import { Col, Container, Row, StateHandler } from "@maxeb/admin-ui";
import { Collection } from "@maxeb/art-sdk";
import { CollectionPublic } from "@maxeb/art-sdk/dist/interfaces/Collection";
import React, { useEffect, useState } from "react";
import Serie from "./Serie";

interface IProps {}

interface IState {
  status: "ok" | "pending" | "init" | "error";
  data: CollectionPublic[];
}

async function get(state: IState, setState: (state: IState) => void) {
  setState({ ...state, status: "pending" });
  try {
    const response = await Collection.getPublic(
      { team: "bccd195b-c067-473d-93f9-dbc3d442e539" },
      ["id", "name", "thumb", "team"],
      10,
      "senaryIndex",
      "ASC"
    );

    if (response.isSuccess()) {
      const result = response.getResult();
      return setState({ ...state, data: result.data, status: "ok" });
    }
  } catch (error) {
    console.error(error);
  }
  setState({ status: "error", data: [] });
}

function Series(props: IProps) {
  const [state, setState] = useState<IState>({ status: "init", data: [] });

  useEffect(() => {
    if (state.status === "init") get(state, setState);
  }, [state]);

  return (
    <Container>
      <StateHandler state={state.status === "init" ? "pending" : state.status}>
        <Row horizontalAlign="center">
          <Col lg={9}>
            <Row spacing={32} vertical>
              {state.data.map((serie) => (
                <Col key={serie.id} sm={5} md={10 / 3}>
                  <Serie
                    id={serie.id || ""}
                    name={serie.name || ""}
                    thumb={serie.thumb}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </StateHandler>
    </Container>
  );
}

export default Series;
