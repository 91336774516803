import React, { Component } from "react";
import { Link, Location, NavigateFunction, Params } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-light-svg-icons/faBars";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import classnames from "classnames";
import withRouter from "./WithRouterWrapper";
import { Container } from "@maxeb/admin-ui";

export interface IProps {
  router: {
    location: Location;
    navigate: NavigateFunction;
    params: Readonly<Params<string>>;
  };
  links: React.ReactNode;
  ref: React.RefObject<HTMLInputElement>;
}

export interface IState {
  displayMobileMenu: boolean;
  show: boolean;
  undock: boolean;
  startPageHeade: boolean;
  underCut: number | null;
  initHeight: number;
}

class Navbar extends Component<IProps, IState> {
  menu: React.RefObject<HTMLDivElement>;
  constructor(props: IProps) {
    super(props);
    this.state = {
      show: false,
      undock: false,
      startPageHeade: true,
      underCut: null,
      initHeight: 213,
      displayMobileMenu: false,
    };
    this.menu = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidUpdate() {
    this.handleScroll();
  }
  handleScroll() {
    if (this.menu.current) {
      const { location } = this.props.router;
      const isStartPage = location.pathname === "/";
      const offset = window.pageYOffset || document.documentElement.scrollTop;
      const { undock, startPageHeade, initHeight } = this.state;
      const carouselHeight = document.documentElement.clientHeight;

      const offsetToUse = isStartPage
        ? document.documentElement.clientHeight + 100
        : 10;

      let underCut: number | null = null;
      if (isStartPage) {
        const menuOffset =
          this.menu.current.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top;

        if (isStartPage && carouselHeight - menuOffset - initHeight < 0)
          underCut = carouselHeight - menuOffset;
      }

      if (isStartPage && !undock && startPageHeade && offset > carouselHeight)
        this.setState({ ...this.state, startPageHeade: false });
      else if (
        isStartPage &&
        !undock &&
        (!startPageHeade || underCut !== this.state.underCut) &&
        offset < carouselHeight
      )
        this.setState({ ...this.state, startPageHeade: true, underCut });
      else if (undock && offset < offsetToUse)
        this.setState({ ...this.state, undock: false, underCut: null });
      else if (!undock && offset > offsetToUse)
        this.setState({ ...this.state, undock: true, underCut: null });
    }
  }
  render() {
    const { location } = this.props.router;
    const { show, undock, startPageHeade, underCut } = this.state;
    const isStartPage = location.pathname === "/" && startPageHeade;
    const showShadow =
      !isStartPage && (undock || (window.innerWidth < 993 && show));

    const underCutStyle =
      isStartPage && underCut !== null
        ? { height: underCut, overflow: "hidden" }
        : {};

    return (
      <nav>
        <div className={isStartPage ? "nav-overflow" : ""}>
          <div
            className={isStartPage ? "nav-holder-white" : "nav-holder"}
            ref={this.menu}
            style={{
              ...underCutStyle,
              boxShadow: showShadow
                ? "0px 0px 3px 0px rgba(0,0,0,0.75)"
                : "none",
              backgroundColor: `rgba(255, 255, 255, ${
                isStartPage ? "0" : "1"
              })`,
              zIndex: 999,
            }}
          >
            <Container fluid={isStartPage}>
              <div className="header-holder">
                <div
                  className={classnames(["logo-holder"])}
                  style={{ width: "300px" }}
                >
                  <div>
                    <Link
                      className="logo-link"
                      to="/"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        this.setState({ ...this.state, show: false });
                      }}
                    >
                      <span
                        className="secondary-logo"
                        style={{ color: isStartPage ? "#fff" : "#000" }}
                      >
                        SASHA OKUN
                      </span>
                    </Link>
                    <FontAwesomeIcon
                      className={isStartPage ? "menu-btn" : "menu-btn-black"}
                      icon={show ? faTimes : faBars}
                      onClick={() => {
                        this.setState({ ...this.state, show: !show });
                      }}
                    />
                  </div>
                </div>
                <div className={"menu-holder" + (!show ? " hide-menu" : "")}>
                  <ul className={classnames(["header-list", !show && ""])}>
                    {React.Children.toArray(this.props.links).map(
                      (link: any) => {
                        return (
                          <li
                            key={"link-" + link.props.children}
                            className={classnames([
                              isStartPage && "white-link",
                              (link.props.to === location.pathname ||
                                (link.props.to === "/artworks" &&
                                  location.pathname.startsWith("/artwork"))) &&
                                "active-link",
                              "link-elment",
                            ])}
                          >
                            {
                              <Link
                                className="link-std"
                                to={link.props.to}
                                onClick={() => {
                                  window.scrollTo(0, 0);
                                  this.setState({ ...this.state, show: false });
                                }}
                              >
                                {link.props.children}
                              </Link>
                            }
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
            </Container>
          </div>
          <div className={location.pathname === "/" ? "" : "nav-placer"} />
        </div>
      </nav>
    );
  }
}

export default withRouter(Navbar);
