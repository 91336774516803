import React, { useState } from "react";
import { Blurhash } from "react-blurhash";

interface IImage {
  className?: string;
  style?: React.CSSProperties;
  src: string;
  hash?: string;
  alt: string;
}

const ImageLoader: React.FC<IImage> = (props) => {
  const [, setLoaded] = useState(false);
  const loaded = false;

  return (
    <div style={{ position: "relative" }}>
      {!loaded && props.hash && (
        <Blurhash className={props.className} hash={props.hash} />
      )}

      {
        <img
          className={props.className}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 1,
            ...(props.style || {}),
          }}
          alt={props.alt}
          src={props.src}
          onLoad={() => {
            setLoaded(true);
          }}
        />
      }
    </div>
  );
};

export default ImageLoader;
