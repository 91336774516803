import React, { useEffect, useState } from "react";
import { ArtworkPublic } from "@maxeb/art-sdk/dist/interfaces/Artwork";
import { Col, ITheme, Row, StateHandler, useClasses } from "@maxeb/admin-ui";
import classnames from "classnames";
import { Artwork } from "@maxeb/art-sdk";
import { useParams } from "react-router-dom";
import ImageLoader from "../../Shared/ImageLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import ImageLightBox from "../../Shared/ImageLightBox";

const styles = (theme: ITheme) => ({
  head: {
    fontWeight: 400,
    cursor: "pointer",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    marginTop: "16px",
    [theme.breakpoints.up("lg")]: {
      textAlign: "right",
      marginTop: "0px",
    },
  },
  activeCategory: {
    fontWeight: 700,
  },
  img: {
    maxWidth: "100%",
    width: "100% !important",
    height: "auto !important",
    aspectRatio: 1,
    objectFit: "cover",
  },
  chevron: {
    fontSize: "1.5rem",
    cursor: "pointer",
  },
  right: {
    textAlign: "right",
  },
  click: {
    cursor: "pointer",
  },
});

interface IProps {
  setActive: (artwork: ArtworkPublic, index: number) => void;
  open: boolean;
  index: number;
  active: ArtworkPublic | null;
  setOpen: (open: boolean) => void;
}
interface IState {
  collection: string;
  categoryId: string;
  status: "init" | "ok" | "pending" | "error";
  start: number;
  end: number;
  data: ArtworkPublic[];
  next?: string;
}
async function get(
  props: IProps,
  state: IState,
  setState: (state: IState) => void
) {
  setState({ ...state, status: "pending" });
  try {
    const response = await Artwork.getPublic(
      {
        collection: state.collection,
        team: "bccd195b-c067-473d-93f9-dbc3d442e539",
      },
      [
        "id",
        "customId",
        "title",
        "thumb",
        "team",
        "year",
        "categoryId",
        "techniqueId",
        "width",
        "height",
      ],
      100,
      "nonaryIndex",
      "ASC"
    );

    if (response.isSuccess()) {
      const result = response.getResult();
      if (!result.next) {
        const filtered = result.data.filter(
          (artwork: ArtworkPublic) => artwork.categoryId === state.categoryId
        );
        props.setActive(filtered[0] || null, 0);
        return setState({ ...state, data: result.data, status: "ok" });
      }
    }
  } catch (err) {
    console.error(err);
  }

  setState({ ...state, status: "error" });
}

function previouse(state: IState, setState: (state: IState) => void) {
  setState({
    ...state,
    start: state.start - 9,
    end: state.end - 9,
  });
}

function next(state: IState, setState: (state: IState) => void) {
  setState({
    ...state,
    start: state.start + 9,
    end: state.end + 9,
  });
}

export default function ArtworksNavigation(props: IProps) {
  const classes = useClasses(styles);
  const params = useParams<{ id: string }>();

  const [state, setState] = useState<IState>({
    collection: params.id || "",
    categoryId: "e347144e-189a-4bea-888b-296ae2879245",
    status: "init",
    start: 0,
    end: 9,
    data: [],
  });

  useEffect(() => {
    if (state.status === "init") get(props, state, setState);
  }, [state, props]);

  const filtered = state.data.filter(
    (artwork) => artwork.categoryId === state.categoryId
  );

  return (
    <>
      <StateHandler state={state.status === "init" ? "pending" : state.status}>
        <div className={classes.head}>
          <span
            className={classnames([
              state.categoryId === "e347144e-189a-4bea-888b-296ae2879245" &&
                classes.activeCategory,
            ])}
            onClick={() => {
              if (state.categoryId !== "e347144e-189a-4bea-888b-296ae2879245")
                setState({
                  ...state,
                  start: 0,
                  end: 9,
                  categoryId: "e347144e-189a-4bea-888b-296ae2879245",
                });
            }}
          >
            Paintings
          </span>{" "}
          |{" "}
          <span
            className={classnames([
              state.categoryId === "e1a08a34-15d0-4589-9215-03452238cc4c" &&
                classes.activeCategory,
            ])}
            onClick={() => {
              if (state.categoryId !== "e1a08a34-15d0-4589-9215-03452238cc4c")
                setState({
                  ...state,
                  start: 0,
                  end: 9,
                  categoryId: "e1a08a34-15d0-4589-9215-03452238cc4c",
                });
            }}
          >
            Drawings
          </span>{" "}
          |{" "}
          <span
            className={classnames([
              state.categoryId === "f6a3e6db-1d44-4ebe-a2f6-55ad9cac4f61" &&
                classes.activeCategory,
            ])}
            onClick={() => {
              if (state.categoryId !== "f6a3e6db-1d44-4ebe-a2f6-55ad9cac4f61")
                setState({
                  ...state,
                  start: 0,
                  end: 9,
                  categoryId: "f6a3e6db-1d44-4ebe-a2f6-55ad9cac4f61",
                });
            }}
          >
            OIL SKETCHES
          </span>
        </div>
        <Row
          spacing={16}
          vertical
          root={{ top: 16, left: 0, right: 0, bottom: 8 }}
        >
          {filtered.slice(state.start, state.end).map((artwork, index) => (
            <Col key={artwork.id} xs={10 / 3}>
              <div
                className={classes.click}
                onClick={() => {
                  props.setActive(artwork, state.start + index);
                }}
              >
                {artwork.thumb && (
                  <ImageLoader
                    className={classes.img}
                    src={artwork.thumb["xs-url"]}
                    hash={artwork.thumb.hash}
                    alt={artwork.title || ""}
                  />
                )}
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col override={{ col: classes.chevron }} xs={5}>
            {0 <= state.start - 9 && (
              <FontAwesomeIcon
                icon={faChevronLeft}
                onClick={() => previouse(state, setState)}
              />
            )}
          </Col>
          <Col
            override={{ col: classnames([classes.chevron, classes.right]) }}
            xs={5}
          >
            {filtered.length > state.start + 9 && (
              <FontAwesomeIcon
                icon={faChevronRight}
                onClick={() => next(state, setState)}
              />
            )}
          </Col>
        </Row>
      </StateHandler>
      {props.active && (
        <ImageLightBox
          open={props.open}
          src={props.active.thumb?.url}
          onClose={() => {
            props.setOpen(false);
          }}
          next={() => {
            if (props.index + 1 < filtered.length)
              props.setActive(filtered[props.index + 1], props.index + 1);
          }}
          previous={() => {
            if (props.index > 0)
              props.setActive(filtered[props.index - 1], props.index - 1);
          }}
        />
      )}
    </>
  );
}
