"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Options {
    constructor() {
        this.name = "IDP";
        this.url = "https://idp.datenbanker.at";
        this.apiKey = "";
    }
    setUrl(newUrl) {
        this.url = newUrl;
    }
    getUrl() {
        return this.url;
    }
    buildPath(path) {
        return this.url + path;
    }
    setApiKey(newApiKey) {
        this.apiKey = newApiKey;
    }
    getApiKey() {
        if (this.apiKey === "")
            throw Error("ApiKey not set for requests to " + this.name);
        return this.apiKey;
    }
}
const options = new Options();
exports.default = options;
